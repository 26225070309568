import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import Container from '../components/Container/Container'
import Image from '../components/Image/Image'
import Form from '../components/Form/Form'

import Try from '../sections/Try/Try'

class BlogPostTemplate extends Component {
    render() {
        const post = this.props.data.markdownRemark
        const siteTitle = this.props.data.site.siteMetadata.title

        const {
            title,
            description,
            author,
            author_description,
            author_tag,
            date,
            tags
        } = post.frontmatter

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <Seo title={title} description={description} />
                <Container>
                    <section className="blog-post__header">
                        <Image
                            className="blog-post__author-image"
                            filename={author
                                .toLowerCase()
                                .split(' ')
                                .join('-')}
                        />
                        <p className="blog-post__author-date">
                            {author}
                            <span className="date">{date}</span>
                        </p>
                        <h1 className="blog-post__title">{title}</h1>
                    </section>

                    <section className="blog-post__content">
                        <div dangerouslySetInnerHTML={{ __html: post.html }} />
                    </section>

                    {author && author_description && author_tag && tags && (
                        <section className="blog-post__author-card">
                            <Image
                                className="blog-post__author-image"
                                filename={author
                                    .toLowerCase()
                                    .split(' ')
                                    .join('-')}
                            />
                            <p className="card__author">
                                {author}
                                <br />
                                {author_description}
                                <br />
                                <span className="card__tag">{author_tag}</span>
                            </p>
                            <p className="card__tags">
                                <span className="card__tags-title">Tags:</span>{' '}
                                {tags.join(', ')}
                            </p>
                        </section>
                    )}

                    <section className="blog-post__subscribe">
                        <Form />
                    </section>
                    <Try />
                </Container>
            </Layout>
        )
    }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        description
        author
        author_description
        author_tag
        tags
      }
    }
  }
`
